.date-picker {
	ngb-datepicker {
		font-size: $font-size-sm;
	}

	.ngb-dp-header,
	.ngb-dp-weekdays {
		--bs-light: #{$theme-50};
	}
	
	.ngb-dp-header {
		padding: 1rem 0.5rem 0.5rem;
	}

	.ngb-dp-arrow {
		.ngb-dp-arrow-btn {
			margin: 0;
			width: 1.75rem;
			height: 1.75rem;
			border-radius: $border-radius;
			display: grid;
			place-content: center;
			color: $headings-color;
	
			& > span {
				display: none;
			}
	
			&:before {
				font-family: $font-family-icon;
				font-size: 1.5rem;
				content: "\e90f";
			}

			&:hover {
				background-color: $theme-200;
				color: inherit;
			}
		}

		&.right {
			.ngb-dp-arrow-btn {
				&:before {
					content: "\e910";
				}
			}
		}
	}
	
	.ngb-dp-navigation-select {
		gap: 0.25rem;
		flex: 1 1 4.5rem;

		& > .form-select {
			box-shadow: none;
			background-color: $white;
			font-size: $font-size-sm;
			background-image: none;
		}
	}

	.ngb-dp-weekday {
		--bs-info: #{$body-color};

		font-weight: $font-weight-bold;
	}

	.ngb-dp-weekday,
	.ngb-dp-week-number {
		font-style: normal;
	}

	.ngb-dp-day {
		.bg-primary {
			background-color: $blue;
		}

		[ngbDatepickerDayView] {
			--bs-btn-bg: #{$theme-100};
			--bs-border-color: #{$theme-100};

			border-radius: $border-radius-sm;
		}
	}

	.ngb-dp-month {
		.ngb-dp-week:first-child {
			margin-bottom: 0.25rem;
		}
 	}

	.ngb-dp-month-name {
		--bs-light: #{$theme-50};

		font-weight: $font-weight-bold;
	}
}