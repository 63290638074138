.time-picker {
	.ngb-tp-input-container {
		& > .btn {
			opacity: 0.5;

			&:hover {
				opacity: 1;
				background-color: transparent;
			}
		}

		.ngb-tp-chevron {
			&:before {
				border-width: 1px 1px 0 0;
				height: 8px;
				width: 8px;
			}
		}
	}

	.is-invalid {
		.form-control {
			border-color: $red;
		}
	}
}
