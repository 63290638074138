.pagination {
	margin-bottom: 0;
}

.page-link {
	width: 1.875rem;
	height: 1.875rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	& > .zwicon {
		font-size: 1.25rem;
	}
}
