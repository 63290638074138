.toolbar {
	height: $toolbar-height;
	background-color: rgba($theme-50, 0.925);
	display: flex;
	align-items: center;
	z-index: $header-z-index - 1;
	position: sticky;
	top: 0;
	padding: 0 $content-padding;
	flex-shrink: 0;
	backdrop-filter: blur(3px);
}