.login {
	display: flex;
	justify-content: center;
	height: 100vh;
	overflow-y: auto;
	padding: 1rem;
}

.login__form {
	max-width: 400px;
	width: 100%;
	padding: 2rem;
	background-color: $white;
	border: 1px solid $theme-200;
	border-radius: $border-radius-lg;
	margin: auto;
}

.login__field {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $input-border-color;
	margin-bottom: 1rem;
	transition: border-color 300ms;
	font-size: $font-size-sm;

	&:focus-within {
		border-color: $input-focus-border-color;
	}

	& > i {
		font-size: 1.25rem;
		margin-right: 0.75rem;
	}

	& > input {
		border: 0;
		padding: 0.75rem 0;
		width: 100%;
		transition: border-color 200ms;
		color: $input-color;

		@include placeholder {
			color: $input-placeholder-color;
		}
	}
}

.login__btn {
	background-image: linear-gradient(270deg, #5691ae 0%, #2b5876 100%);
	border-radius: $border-radius;
	border: 0;
	height: 2.25rem;
	padding: 0 2rem;
	color: $white;
	flex: 1;
	display: block;
	width: 100%;
	font-weight: $font-weight-bold;
	font-size: $font-size-sm;

	&:hover {
		background: #2b5876;
	}
}
