.html-editor {
	width: 100%;
	padding: 0.25rem 0.1rem;

	.ql-editor {
		height: auto;
		width: 100%;

		&.ql-blank {
			&:before {
				color: $text-muted;
			}
		}
	}

	.ql-snow.ql-toolbar {
		button {
			margin-right: 1px;
			border-radius: $border-radius-sm;

			&:hover,
			&.ql-active {
				.ql-stroke {
					stroke: $link-color;
				}

				.ql-fill {
					fill: $link-color;
				}
			}

			&.ql-active {
				background-color: var(--theme-100);
			}
		}

		.ql-picker-options {
			border-color: $dropdown-border-color;
			box-shadow: $dropdown-box-shadow;
		}

		.ql-picker.ql-expanded {
			.ql-picker-options {
				border-color: $dropdown-border-color;
			}

			.ql-picker-label {
				border-color: transparent;

				.ql-stroke {
					stroke: $link-color;
				}
			}
		}
	}

	.ql-toolbar,
	.ql-container {
		border: 0;
	}

	.ql-container {
		height: auto;
	}

	.ql-snow {
		.ql-stroke {
			stroke: $body-color;
			stroke-width: 1.5;
		}

		.ql-fill {
			fill: $text-muted;
		}
	}

	.ql-snow {
		.ql-tooltip {
			color: $body-color;
			border-radius: $dropdown-border-radius;
			border-color: $dropdown-border-color;
			box-shadow: $dropdown-box-shadow;

			input[type='text'] {
				border-color: $input-border-color;
				border-radius: $input-border-radius;
				color: $input-color;
				font-size: 0.8rem;

				@include placeholder {
					color: $input-placeholder-color;
				}
			}
		}
	}
}
