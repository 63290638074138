// Light - 300
@font-face {
	font-family: 'Roboto';
	src: url('../fonts/roboto/roboto-light.woff2') format('woff2'),
		url('../fonts/roboto/roboto-light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

// Regular - 400
@font-face {
	font-family: 'Roboto';
	src: url('../fonts/roboto/roboto-regular.woff2') format('woff2'),
		url('../fonts/roboto/roboto-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

// Medium - 500
@font-face {
	font-family: 'Roboto';
	src: url('../fonts/roboto/roboto-medium.woff2') format('woff2'),
		url('../fonts/roboto/roboto-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}
