@media print {
	@page {
		margin: 0;
		size: auto;
	}

	body {
		margin: 0mm 0mm 0mm 0mm !important;
		padding: 0mm !important;
	}

	.sidebar,
	.aside,
	.toolbar,
	.header,
	.footer {
		display: none !important;
	}

	.print-area {
		-webkit-print-color-adjust: exact !important;
	}
}
