// Hide scrollbar
.hide-scrollbar {
	-ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}


// Link variations
.link,
.link-muted,
.link-body,
.link-headings {
	transition: color 300ms;
	cursor: pointer;
}

.link {
	color: $link-color;

	&:hover,
	&.show {
		color: $link-hover-color;
	}
}

.link-muted {
	color: $text-muted;

	&:hover,
	&.show {
		color: $body-color;
	}
}

.link-body {
	color: $body-color;

	&:hover,
	&.show {
		color: $headings-color;
	}
}

// Backdrop
.backdrop {
	cursor: pointer;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

// Widget lists
.widget-lists {
	column-gap: 1.25rem;

	& > * {
		display: inline-block;
		width: 100%;
		margin-bottom: 1.25rem;
	}

	@media (min-width: 1600px) {
		column-count: 3;
	}

	@media (min-width: 900px) and (max-width: 1599px) {
		column-count: 2;
	}

	@media (max-width: 899px) {
		display: flex;
		flex-direction: column;
		column-count: 1;
	}
}

// Switch bullets
.switch-indicators {
	& > i {
		width: 7px;
		height: 7px;
		position: relative;
		border-radius: 50%;
		display: inline-block;
		margin: 0 0.25rem;
		cursor: pointer;
		background-color: $theme-200;
		transition: background-color 300ms;

		&:after {
			content: "";
			width: 7px;
			height: 7px;
			border-radius: 50%;
			transform: translateY(10px);
			opacity: 0;
			transition: opacity 300ms, transform 300ms, background-color 300ms;
			position: absolute;
			left: 0;
			top: 0;
		}

		&.active {
			background-color: $white;

			&:after {
				background-color: $blue;
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}

// Icons
.icon {
	min-width: 1.85rem;
	height: 1.85rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	transition: color 300ms, background-color 300ms;

	&,
	&:hover {
		color: currentColor;
	}

	&.zwicon,
	& > .zwicon {
		&:not([class*="fs-"]) {
			font-size: 1.2rem;
		}
	}
}