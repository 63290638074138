.chart {
	position: relative;
	height: 225px;

	& > canvas {
		position: relative;
		z-index: 1;
	}
}

.chart-lg {
	height: 300px;
}

.chart-legends {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	gap: 0 1rem;

	& > li {
		display: flex;
		align-items: center;
		font-size: $font-size-sm;
		color: $theme-700;

		& > i {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 0.5rem;
		}
	}
}

.chart-nav {
	.drag-scroll-content {
		padding: 0 1.75rem;
	}
}