.ng-select {
	.ng-select-container {
		border-color: $input-border-color;
		border-radius: $input-border-radius;
		min-height: $input-height;

		&:hover {
			box-shadow: none;
		}

		.ng-value-container {
			padding-left: $input-padding-x;
			color: $input-color;
		}
	}

	&.ng-select-single {
		.ng-select-container {
			height: $input-height;

			.ng-value-container {
				.ng-input {
					top: 8px;
					padding-left: $input-padding-x;
					color: $input-color;
					padding-right: 40px;

					& > input {
						color: $input-color;
					}
				}

				.ng-placeholder {
					color: $input-placeholder-color;
				}
			}
		}
	}

	&.ng-select-multiple {
		.ng-select-container {
			.ng-value-container {
				.ng-placeholder {
					top: 8px;
					padding-bottom: 0;
					padding-left: 6px;
					color: $input-placeholder-color;
				}

				.ng-value {
					padding: 3px;
					color: $headings-color;

					.ng-value-icon {
						&.left {
							border: 0;
							display: none;
						}
					}
				}

				.ng-input {
					& > input {
						color: $input-color;
					}
				}
			}
		}
	}

	&.ng-select-opened {
		.ng-select-container {
			border-color: $input-focus-border-color;
		}

		&.ng-select-bottom {
			z-index: 9;
		}
	}

	&.ng-select-focused {
		&:not(.ng-select-opened) {
			.ng-select-container {
				border-color: $input-focus-border-color;
				box-shadow: none;
			}
		}
	}

	.ng-arrow-wrapper {
		position: relative;
		align-self: flex-end;
		width: 13px;
		height: 13px;

		.ng-arrow {
			opacity: 0;
			visibility: hidden;
		}
	}

	.ng-clear-wrapper {
		color: $text-muted;
		text-align: right;

		&:hover {
			.ng-clear {
				color: $body-color;
			}
		}
	}
}

.ng-dropdown-panel {
	border-color: $dropdown-border-color;
	box-shadow: $dropdown-box-shadow;

	&.ng-select-top {
		border-bottom-color: $input-focus-border-color;
		border-top-right-radius: $border-radius;
		border-top-left-radius: $border-radius;
	}

	&.ng-select-bottom {
		border-top-color: $input-focus-border-color;
		border-bottom-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
	}

	.ng-dropdown-panel-items {
		padding: $dropdown-padding-y;

		.ng-option {
			color: $dropdown-link-color;
			border-radius: $border-radius-sm;
			font-size: $dropdown-font-size;

			&.ng-option-marked {
				background-color: $dropdown-link-hover-bg;
				color: $dropdown-link-color;
			}

			&.ng-option-selected {
				&,
				&.ng-option-marked {
					background-color: $dropdown-link-active-bg;
					color: $dropdown-link-active-color;

					.ng-option-label {
						font-weight: normal;
					}
				}
			}

			&.ng-option-disabled {
				color: $theme-700;
				opacity: 0.75;
			}
		}

		.ng-optgroup {
			color: var(--theme-900);
            font-weight: $font-weight-bold;
			text-transform: capitalize;
			padding: 0.5rem 1rem;
		}
	}

	.scroll-host {
		scrollbar-width: thin;
		scrollbar-color: $scrollbar-bg transparent;

		&::-webkit-scrollbar-thumb {
			background-color: $scrollbar-bg;
		}

		&:hover {
			scrollbar-color: $scrollbar-hover-bg transparent;

			&::-webkit-scrollbar-thumb {
				background-color: $scrollbar-bg;
			}
		}

		&::-webkit-scrollbar {
			width: 11px;
			height: 11px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 1rem;
			border: 3px solid transparent;
			background-clip: content-box;

			&:hover {
				background-color: $scrollbar-hover-bg;
			}
		}
	}
}