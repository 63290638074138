// Options
$enable-shadows: true;
$enable-caret: false;
$enable-important-utilities: false;
$enable-negative-margins: true;
$enable-cssgrid: true;

// Theme colors
$theme-50: #f8fcff;
$theme-100: #f0f7fc;
$theme-200: #e0f0fb;
$theme-300: #d4eafa;
$theme-400: #c3e0f4;
$theme-500: #b4d6ed;
$theme-600: #9fc6e1;
$theme-700: #6c9dbf;
$theme-800: #36688c;
$theme-900: #205377;

// Colors
$white: #fff;
$black: #000;
$red: #fd7171;
$blue: #1e91ff;
$green: #43d271;
$orange: #ffa950;
$purple: #e26cf5;
$cyan: #26c6da;
$teal: #26a69a;
$lime: #d4e157;
$amber: #ffca28;
$deep-purple: #7e57c2;
$indigo: #5c6bc0;
$pink: #f06292;
$yellow: #ffeb3b;
$light: #e7f0f7;

$theme-colors: (
	"blue": $blue,
	"indigo": $indigo,
	"purple": $purple,
	"deep-purple": $deep-purple,
	"pink": $pink,
	"red": $red,
	"orange": $orange,
	"yellow": $yellow,
	"amber": $amber,
	"green": $green,
	"lime": $lime,
	"teal": $teal,
	"cyan": $cyan,
	"black": $black,
	"white": $white,

	"primary": $blue,
	"secondary": $theme-700,
	"success": $green,
	"info": $cyan,
	"warning": $amber,
	"danger": $red,
	"light": $theme-200,
	"dark": $theme-900
);

$gradient-shadows: (
	"pink": 0 3px 5px 0 rgba(221, 74, 209, 0.35),
	"purple": 0 3px 5px 0 rgba(126, 110, 241, 0.35),
	"green": 0 3px 5px 0 rgba(95, 171, 176, 0.5),
	"red": 0 3px 5px 0 rgba(254, 71, 95, 0.35),
	"amber": 0 3px 5px 0 rgba(193, 137, 36, 0.25),
	"blue": 0 3px 5px 0 rgba(18, 116, 170, 0.35),
	"peach": 0 3px 5px 0 rgba(255, 149, 140, 0.25),
	"orange": 0 3px 5px 0 rgba(248, 121, 89, 0.39),
	"charcoal": 0 3px 5px 0 rgba(53, 84, 113, 0.35),
	"perano": 0 3px 5px 0 rgba(125, 162, 215, 0.35),
	"cyan": 0 3px 5px 0 rgba(60, 193, 198, 0.4),
	"plum": 0 3px 5px 0 rgba(111, 95, 190, 0.3),
	"ace": 0 3px 3px 0 rgba(164, 181, 208, 0.2),
	"lime": 0 3px 5px 0 rgba(99, 227, 128, 0.4),
	"pine": 0 3px 5px 0 rgba(105, 78, 117, 0.4),
	"navy": 0 3px 5px 0 rgba(21, 177, 222, 0.35)
);

// Font
$font-size-base: 0.84375rem;
$font-size-sm: 0.75rem;
$font-size-xs: 0.625rem;
$font-size-lg: 0.96875rem;
$font-size-xl: 1.25rem;
$font-size-2xl: 1.5rem;
$font-size-3xl: 1.75rem;
$font-size-4xl: 2rem;

$font-sizes: (
	1: $font-size-4xl,
	2: $font-size-3xl,
	3: $font-size-2xl,
	4: $font-size-xl,
	5: $font-size-lg,
	6: $font-size-base,
	7: $font-size-sm,
	8: $font-size-xs
);

// Spacer
$spacer: 1rem;
$spacers: (
	px: 1px,
	0: 0,
	0\.5: $spacer * 0.125,
	1: $spacer * 0.25,
	1\.5: $spacer * 0.375,
	2: $spacer * 0.5,
	2\.5: $spacer * 0.625,
	3: $spacer * 0.75,
	3\.5: $spacer * 0.875,
	4: $spacer,
	5: $spacer * 1.25,
	6: $spacer * 1.5,
	7: $spacer * 1.75,
	8: $spacer * 2,
	9: $spacer * 2.25,
	10: $spacer * 2.5,
	11: $spacer * 2.75,
	12: $spacer * 3,
	14: $spacer * 3.5,
	16: $spacer * 4,
	20: $spacer * 5,
	24: $spacer * 6,
	28: $spacer * 7,
	32: $spacer * 8,
	36: $spacer * 9,
	40: $spacer * 10,
	/*   44: $spacer * 11,
	48: $spacer * 12,
	52: $spacer * 13,
	56: $spacer * 14,
	60: $spacer * 15,
	64: $spacer * 16,
	68: $spacer * 17,
	72: $spacer * 18,
	80: $spacer * 20,
	96: $spacer * 24, */
);

// body
$body-bg: $theme-50;
$body-color: $theme-800;
$body-font-size: $font-size-base;

// Color
$headings-color: $theme-900;
$text-muted: #799fba;

// Border Radius
$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 1rem;

// Breadcrumbs
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider-color: $theme-600;
$breadcrumb-item-active-color: $body-color;
$breadcrumb-item-padding-x: 0.25rem;
$breadcrumb-active-color: $body-color;

// Nav
$nav-link-padding-x: 0.65rem;
$nav-link-padding-y: 0.5rem;
$nav-link-hover-color: $headings-color;
$nav-link-color: $body-color;
$nav-tabs-border-width: 0;
$nav-tabs-link-color: $body-color;
$nav-tabs-link-active-color: $blue;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-border-radius: 0;
$nav-pills-border-radius: $border-radius-sm;
$nav-link-disabled-color: $text-muted;

// Progress Bar
$progress-box-shadow: none;
$progress-bar-color: $blue;
$progress-bg: $theme-200;
$progress-height: 0.2rem;

// Card
$card-border-color: $theme-200;
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;
$card-title-spacer-y: 1rem;
$card-border-radius: $border-radius-lg;
$card-cap-bg: $white;

// Tooltip
$tooltip-bg: #192f40;
$tooltip-color: #deeeff;
$tooltip-padding-x: 0.75rem;
$tooltip-padding-y: 0.5rem;
$tooltip-border-radius: $border-radius-sm;
$tooltip-font-size: $font-size-sm;
$tooltip-opacity: 1;

// Font
$font-family-sans-serif: "Roboto", sans-serif;
$font-family-icon: "zwicon";
$font-family-base: $font-family-sans-serif;
$font-weight-bold: 500;

// Links
$link-hover-decoration: none;
$link-decoration: none;

// Layout
$content-padding: 1.25rem;
$content-sidebar-box-shadow: 2px 10px 15px rgba(132, 172, 197, 0.25);

// Sidebar
$sidebar-width: 16.875rem;
$sidebar-bg: linear-gradient(179deg, #2b5876 0%, #16222a 100%);
$sidebar-box-shadow: 2px 2px 4px 0 rgba(75, 149, 199, 0.34);
$sidebar-z-index: 20;
$sidebar-bar-width: 3.5rem;
$sidebar-bar-bg: rgba($black, 0.1);

// Menu
$menu-link-color: #7ca5d3;
$menu-title-color: #3f74ac;
$menu-link-active-color: #cfe6ff;
$menu-link-hover-color: #cfe6ff;

// Header
$header-z-index: $sidebar-z-index - 1;
$header-height: 3.2rem;
$header-bottom-height: 50px;
$header-toggle-bg: #254861;
$header-border-color: $theme-200;

// Logo
$logo-color: #cfe6ff;
$logo-small-color: #4d85c1;

// Form
$input-placeholder-color: $theme-700;
$input-color: $theme-800;
$input-bg: $white;
$input-box-shadow: 0 0 0 transparent;
$input-focus-box-shadow: 0 0 0 0.2rem rgba($theme-700, 0.1);
$input-border-color: #c9dfef;
$input-focus-border-color: darken($input-border-color, 10%);
$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius;
$input-disabled-bg: $theme-50;
$input-disabled-border-color: $theme-300;
$input-padding-y: 0.5rem;
$input-font-size: $font-size-base;
$input-color: $body-color;
$input-focus-color: $input-color;

$form-label-margin-bottom: 0.25rem;

$form-feedback-icon-valid: url("~src/assets/img/icons/form/valid.svg");
$form-feedback-icon-invalid: url("~src/assets/img/icons/form/invalid.svg");
$form-feedback-tooltip-padding-y: 0.5rem;
$form-feedback-tooltip-padding-x: 1rem;

$form-check-input-checked-bg-image: url("~src/assets/img/icons/checkbox/checked.svg");
$form-check-input-width: 1.2592592592592593em;
$form-check-input-border-radius: 50%;
$form-check-input-border: 1px solid $theme-800;
$form-check-input-bg: transparent;
$form-check-input-focus-border: $form-check-input-border;
$form-check-input-focus-box-shadow: $input-focus-box-shadow;
$form-check-input-active-filter: none;
$form-check-input-checked-bg-color: $theme-800;
$form-check-input-checked-border-color: transparent;
$form-check-margin-bottom: 0;

$form-file-button-hover-bg: $theme-100;

$form-select-box-shadow: none;
$form-select-indicator-color: $text-muted;
$form-select-bg-size: 14px 10px;
$form-select-box-shadow: 0 0 0 0 transparent;
$form-select-focus-box-shadow: $input-focus-box-shadow;
$form-select-disabled-bg: $input-disabled-bg;

$form-switch-color: #bfcfdf;
$form-switch-checked-color: $blue;
$form-switch-width: 2.5em;
$form-switch-transition: background-position 200ms, border-color 250ms;

// Dropdown
$dropdown-border-color: $theme-200;
$dropdown-box-shadow: 0 10px 20px rgba(21, 62, 95, 0.1);
$dropdown-link-color: $body-color;
$dropdown-item-padding-x: 0.6rem;
$dropdown-item-padding-y: 0.4rem;
$dropdown-item-font-size: 10px;
$dropdown-link-hover-bg: $theme-100;
$dropdown-link-active-bg: $theme-200;
$dropdown-link-active-color: $headings-color;
$dropdown-link-hover-color: $theme-900;
$dropdown-link-disabled-color: $text-muted;
$dropdown-header-color: $white;
$dropdown-divider-bg: $theme-200;
$dropdown-padding-y: 0.25rem;
$dropdown-padding-x: 0.25rem;

// Table
$table-bg: $white;
$table-border-color: $theme-200;
$table-striped-bg: $theme-50;
$table-hover-bg: $theme-100;
$table-bg-level: -9;
$table-border-level: -9;

// Toolbar
$toolbar-height: 2.8125rem;

// Carousel
$carousel-indicator-width: 7px;
$carousel-indicator-height: 7px;
$carousel-control-prev-icon-bg: url("~src/assets/img/icons/carousel/prev.svg");
$carousel-control-next-icon-bg: url("~src/assets/img/icons/carousel/next.svg");
$carousel-control-icon-width: 30px;

// Button
$btn-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-focus-width: 0;
$btn-link-disabled-color: $text-muted;
$btn-link-hover-color: currentColor;
$btn-link-color: currentColor;
$btn-hover-bg-shade-amount: 5%;
$btn-hover-border-shade-amount: 5%;
$btn-active-bg-shade-amount: 7.5%;
$btn-active-border-shade-amount: 7.5%;
$btn-padding-y-sm: 0.35rem;
$btn-padding-y-sm: 0.275rem;
$btn-padding-x-sm: 0.75rem;
$btn-font-size-sm: $font-size-sm;
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius;

// Input group
$input-group-addon-bg: $theme-50;

// YIQ
$color-contrast-dark: $theme-900;
$min-contrast-ratio: 1.5;

// Date picker
$date-picker-chevron-left-icon: url("~src/assets/img/icons/chevron-left.svg");
$date-picker-chevron-right-icon: url("~src/assets/img/icons/chevron-right.svg");

// hr
$hr-border-color: $theme-200;
$hr-opacity: 1;

// Badge
$badge-border-radius: 0.15rem;

// Modal
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;
$modal-content-border-width: 0;
$modal-header-padding: 1.5rem;
$modal-footer-padding: 1.5rem;
$modal-backdrop-bg: #03111b;
$modal-backdrop-opacity: 0.5;
$modal-inner-padding: 1.5rem;
$modal-title-line-height: 100%;

// Close
$btn-close-focus-shadow: none;
$btn-close-width: 0.5rem;
$btn-close-color: $headings-color;

// Code
$pre-bg: #f4f8fb;
$pre-color: #1c548a;

// Blockquotes
$blockquote-footer-color: $text-muted;
$blockquote-margin-y: 0.5rem;

// Alert
$alert-border-width: 0;
$alert-padding-y: 1.25rem;
$alert-padding-x: 1.5rem;

// Pagination
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-padding-y-sm: 0;
$pagination-padding-x-sm: 0;
$pagination-padding-y-lg: 0;
$pagination-padding-x-lg: 0;
$pagination-border-width: 0;
$pagination-border-radius: 50%;
$pagination-font-size: $font-size-sm;
$pagination-color: $body-color;
$pagination-focus-box-shadow: none;
$pagination-focus-bg: $theme-100;
$pagination-focus-color: $headings-color;
$pagination-hover-color: $headings-color;
$pagination-hover-bg: $theme-100;
$pagination-active-bg: $theme-900;
$pagination-disabled-color: $theme-500;

// Popover
$popover-header-padding-y: 1rem;
$popover-header-padding-x: 1rem;
$popover-body-padding-y: 1rem;
$popover-body-padding-x: 1rem;
$popover-header-bg: transparent;
$popover-border-color: $theme-200;
$popover-box-shadow: 0 3px 10px rgba(#153e5f, 0.05);

// List group
$list-group-border-color: $theme-200;
$list-group-disabled-color: $text-muted;
$list-group-color: $body-color;
$list-group-active-bg: $theme-100;
$list-group-active-color: $headings-color;
$list-group-active-border-color: $theme-200;
$list-group-hover-bg: $theme-50;
$list-group-item-padding-y: 0.75rem;

// Toast
$toast-border-color: $theme-200;
$toast-box-shadow: $dropdown-box-shadow;
$toast-header-color: $headings-color;
$toast-font-size: $font-size-sm;
$toast-padding-x: 1rem;
$toast-padding-y: 0.55rem;
$toast-header-border-color: $theme-200;

// Spinners
$spinner-height-sm: 1.25rem;
$spinner-width-sm: 1.25rem;
$spinner-border-width: 2px;
$spinner-border-width-sm: 1px;

// Accordion
$accordion-bg: $white;
$accordion-button-active-bg: $theme-50;
$accordion-button-color: $headings-color;
$accordion-button-active-color: $headings-color;
$accordion-icon-width: 0.75rem;

// Scrollbar
$scrollbar-bg: #dce5f1;
$scrollbar-hover-bg: darken($scrollbar-bg, 5%);

// Image
$thumbnail-box-shadow: none;
$thumbnail-border-color: $theme-200;

@import "bootstrap/scss/variables";