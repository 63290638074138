// Sidebar toggle mixin to re-use
@mixin aside-toggle() {
	position: absolute;
	top: $header-height + 0.0625rem;
	z-index: $header-z-index + 1;
	border: 0;
	height: calc(100vh - ($header-height + 0.0625rem));

	&, .toolbar {
		background-color: $white;
	}

	&:not(.aside--active) {
		pointer-events: none;
	}

	&.aside--active {
		transform: translate3d(0, 0, 0) !important;
		box-shadow: $content-sidebar-box-shadow;

		.aside__close {
			opacity: 1;
		}
	}

	&:not(.aside--right) {
		left: 0;
		transform: translate3d(-101%, 0, 0);
	}

	// Sidebar to be appeared on right side of the screen
	&.aside--right {
		right: 0;
		transform: translate3d(101%, 0, 0);
	}
}

// Sidebars used within content
// i.e files, contacts, messages, profile
.aside {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	transition: transform 300ms;
	will-change: transform, opacity;
	overflow-y: auto;

	&:not(.aside--lg):not(.aside--sm) {
		width: $sidebar-width;
	}

	&:not(.aside--right) {
		//border-right: 1px solid $theme-100;

		.aside__close {
			right: -2rem;
		}
	}

	&.aside--right {
		border-left: 1px solid $theme-200;

		.aside__close {
			left: -2rem;
		}
	}
}

// Hide sidebar by default
.aside--hidden {
	@include aside-toggle();
}

// Add this modifier class to hide the sidebar in sm breakpoint
.aside--hidden-sm {
	@include media-breakpoint-down(sm) {
		@include aside-toggle();
	}
}

// Add this modifier class to hide the sidebar in md breakpoint
.aside--hidden-md {
	@include media-breakpoint-down(md) {
		@include aside-toggle();
	}
}

// Add this modifier class to hide the sidebar in lg breakpoint
.aside--hidden-lg {
	@include media-breakpoint-down(lg) {
		@include aside-toggle();
	}
}

// Add this modifier class to hide the sidebar in xl breakpoint
.aside--hidden-xl {
	@include media-breakpoint-down(xl) {
		@include aside-toggle();
	}
}

.aside--hidden-xxl {
	@include media-breakpoint-down(xxl) {
		@include aside-toggle();
	}
}

// By default, sidebars are meant to be placed on left side.
// Sidebar to display in right side.
.aside--right {
	&:not(.aside--active) {
		border-left: 1px solid $theme-200;
	}
}

// Additional sidebar sizes
.aside--lg {
	width: $sidebar-width + 4;
}

.aside--sm {
	width: $sidebar-width - 3;
}

// Some sidebars are used as entire page in mobile and smaller screens
// This helps to prevent of having more than 2 sidebars in a single page
// i.e Contacts (in mobile and smaller screens)
.aside--page {
	transition: opacity 300ms, transform 300ms;

	@include media-breakpoint-down(md) {
		width: 100%;
		position: absolute;
		left: 0;
		top: $header-height;
		height: calc(100% - $header-height)
	}
}

.aside-link {
	display: flex;
	align-items: center;
	border-radius: 0 $border-radius $border-radius 0;
	padding: 0.55rem 0.55rem 0.55rem 1.15rem;
	color: $headings-color;

	&:hover {
		background-color: $theme-100;
		color: $headings-color;
	}

	&.active {
		background-color: $theme-200;
	}

	.badge {
		--bs-badge-padding-x: 0.4rem;
		--bs-badge-padding-y: 0.4rem;
		--bs-badge-font-size: #{$font-size-xs};
	}
}