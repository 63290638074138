button {
	border: 0;
	background-color: transparent;
	appearance: none;
}

.btn-link {
	--bs-btn-hover-bg: #{$theme-100};
}

.btn-close {
	font-weight: $font-weight-bold;
}

.dropdown {
	&.show {
		& > .btn.dropdown-toggle {
			background-color: var(--bs-btn-active-bg);
			color: var(--bs-btn-active-color);
			border-color: var(--bs-btn-active-bg);
		}
	}
}