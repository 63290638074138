// Functions, Mixins and Variables
@import "bootstrap/scss/functions";
@import "bootstrap/scss/mixins";

// Placeholder
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

// CSS Animations based on animate.css
@mixin animate($name, $duration, $count: 1) {
	animation-name: $name;
	animation-duration: $duration;
	animation-fill-mode: both;
	animation-iteration-count: $count;
}

// Font icon (for before/after content)
@mixin font-icon($content, $font-size) {
	font-family: $font-family-icon;
	content: $content;
	font-size: $font-size;
}

// Hide scrollbar but keep it functional
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

// Hover links
// @mixin link-hover($color: $muted-link-color, $hover-color: $link-color) {
// 	color: $color;

// 	&:hover {
// 		color: $hover-color;
// 	}
// }
