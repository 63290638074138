.dropdown-item {
	border-radius: $border-radius-sm;
	transition: color 300ms, background-color 300ms;
	display: flex;
	align-items: center;
	font-size: $font-size-sm;

	& > .zwicon {
		font-size: 1.2rem;
		margin: 0 0.5rem 0 -0.2rem;
	}
}