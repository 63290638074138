.input-slider.ngx-slider {
	margin: 10px 0 7px;

	.ngx-slider-bubble {
		font-size: 0.9rem;

		&,
		&.ngx-slider-limit {
			color: $theme-700;
		}
	}

	.ngx-slider-active {
		&.ngx-slider-pointer {
			transform: scale(1.5);
			box-shadow: 0 0 0 5px rgba($black, 0.05);
		}
	}

	.ngx-slider-bar {
		height: 2px;
		background-color: $theme-200;
	}

	.ngx-slider-selection {
		background-color: $blue;
	}

	.ngx-slider-pointer {
		width: 12px;
		height: 12px;
		top: -5px;
		transform-origin: center;
		transform: scale(1);
		background-color: $blue;
		box-shadow: 0 0 0 0 rgba($black, 0.05);
		

		&:after {
			display: none;
		}
	}

	&.animate {
		.ngx-slider-pointer {
			transition: 300ms;
		}
	}

	&.ngx-slider-vertical {
		.ngx-slider-bar {
			width: 2px;
		}
		.ngx-slider-pointer {
			left: -5px !important;
		}
	}
}
