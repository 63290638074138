.nav-link {
	border-radius: $border-radius;
	transition: color 300ms, background-color 300ms;
	display: flex;
	align-items: center;
}

.nav-link-active {
	background-color: $theme-200;
}

.nav {
	&:not(.nav-tabs):not(.nav-pills) {
		.nav-link:not(.nav-link-active):hover {
			background-color: darken($theme-100, 1%);
			color: $headings-color;
		}
	}
}

.nav-scroll {
	@include hide-scrollbar();
	
	white-space: nowrap;
	overflow-x: auto;
	flex-wrap: nowrap;

	& > .nav-link {
		color: currentColor;

		&.active {
			color: $blue;
			background-color: transparent;
		}
	}
}

.nav-pills,
.nav-tabs {
	.nav-link {
		font-weight: $font-weight-bold;
	}
}

.nav-tabs {
	gap: 1rem;

	.nav-link {
		--bs-nav-link-padding-x: 0;

		border-radius: 0;
		
		&.active {
			box-shadow: 0 2px 0 0 $blue;
		}

		&:not(.active) {
			&:hover {
				background-color: transparent;
				box-shadow: 0 2px 0 0 $theme-300;
			}
		}
	}
}

.nav-pills {
	--bs-nav-link-padding-y: 0.4rem;
}