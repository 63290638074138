.toast-header {
	strong {
        font-weight: $font-weight-bold;
		line-height: 100%;
	}
}

.toast-dark {
	--bs-toast-border-color: transparent;
	--bs-toast-header-border-color: transparent;
}