color-picker {
	.color-picker {
		border-color: $dropdown-border-color;
		padding-bottom: 10px;
		margin-top: -10px;

		.arrow {
			display: none;
		}

		.selected-color {
			border: 2px solid $white;
			box-shadow: 0 0 0 1px $theme-200;
		}

		.hue {
			margin-bottom: 10px;
		}

		.cursor {
			border-width: 1px;
			border-color: var(--theme-900);
		}

		.hsla-text,
		.rgba-text,
		.hex-text {
			.box {
				padding: 0 55px 0 12px;

				div {
					color: $body-color;
				}

				input {
					border-color: $input-border-color;
					border-radius: $input-border-radius;
					height: 35px;
					color: $input-color;
					font-size: 0.9rem;
				}
			}
		}

		.type-policy {
			right: 20px;
			height: 35px;
			width: 35px;
			border: 1px solid #d3e3f4;
			background: #fff;
			border-radius: 3px;
			top: 217px;
			background: url(/assets/img/icons/color-palette.svg) no-repeat center;
			background-size: 15px;

			&:hover {
				background-color: var(--theme-100);
			}

			.type-policy-arrow {
				opacity: 0;
				cursor: pointer;
			}
		}
	}
}
