.card-title {
	font-weight: $font-weight-bold;
	font-size: $font-size-lg;
	margin-top: -0.1rem;
}

.card-subtitle {
	font-weight: normal;
	color: $body-color;
	line-height: 1.5;
	font-size: $font-size-base;
}

.card__banner {
	border-radius: 0.3rem;
	margin: 0.35rem 0.35rem 0 0.35rem;
	padding: 1.75rem;
}

.card-footer,
.card-header {
	font-size: 0.9rem;
}

.card-footer {
	padding: 1rem $card-spacer-x 1.15rem;
}

.card-header {
	padding: 1.15rem $card-spacer-x 1rem;
}