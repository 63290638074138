@mixin content-sidebar-toggle {
	position: absolute;
	right: 0;
	top: $toolbar-height;
	height: calc(100% - #{$toolbar-height});
	z-index: $header-z-index + 1;
	transform: translate3d($sidebar-width, 0, 0);
	opacity: 0;
	transition: transform 300ms, opacity 300ms;
	border: 0;
	padding-bottom: $toolbar-height;
	border-bottom-left-radius: $border-radius-xl;

	&.content__sidebar--active {
		transform: translate3d(0, 0, 0);
		opacity: 1;
		box-shadow: $content-sidebar-box-shadow;
	}
}

.content {
	display: flex;
	width: 100%;
	flex: 1 1 auto;
	overflow: hidden;
}

.content__inner {
	flex: 1 1 auto;
	transition: transform 300ms, opacity 300ms;
	position: relative;
	display: flex;
	flex-direction: column;
}

.layout--aside {
	@include media-breakpoint-down(md) {
		.content__inner {
			opacity: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			height: calc(100% - $header-height);
			top: $header-height;
			transform: translate3d(100%, 0, 0);
		}
	}
}

.layout--aside-toggled {
	@include media-breakpoint-down(md) {
		.aside--page {
			opacity: 0;
			pointer-events: none;
			transform: translate3d(-100%, 0, 0);
		}

		.content__inner {
			opacity: 1;
			pointer-events: auto;
			transform: translate3d(0, 0, 0);
		}
	}
}
