$tooltip-bg-color: $tooltip-bg;
$tooltip-font-family: $font-family-sans-serif;
$tooltip-shadow: none;
$tooltip-radius: $tooltip-border-radius;
$tooltip-font-size: $tooltip-font-size;
$tooltip-padding: $tooltip-padding-y $tooltip-padding-x;
$zoom-btns-bg-color: rgba($black, 0.2);
$zoom-btns-radius: $border-radius-sm;

.jvm-container {
    .jvm-tooltip {
        color: $tooltip-color;
        pointer-events: none;
    }

    .jvm-zoom-btn {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;

        &.jvm-zoomin {
            top: 0;
        }

        &.jvm-zoomout {
            top: 31px;
        }
    }
}

@import "jsvectormap/src/scss/jsvectormap.scss";