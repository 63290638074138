* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus,
	&:focus-visible {
		outline: none;
	}
}

html,
body {
	height: 100%;
	overflow-x: hidden;
}

a {
	cursor: pointer;
	text-decoration: none;
}

pre {
	background-color: $pre-bg;
	padding: 1.5rem;
	border-radius: $border-radius;
}