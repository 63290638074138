::placeholder {
	opacity: 1;
	color: $input-placeholder-color;
}

input,
textarea {
	resize: none;
	color: $input-color;
	-ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

div.form-control {
	&:focus-within {
		border-color: $input-focus-border-color;
		box-shadow: $input-focus-box-shadow;
	}
}

.form-switch {
	.form-check-input {
		border: 1px solid $form-switch-color;

		&:checked {
			background-color: transparent;
			border-color: $form-switch-checked-color;
		}
	}
}