.scrollbar-control {
	--vertical-scrollbar-total-size: 4;
}

.ng-scrollbar-thumb {
	--scrollbar-thumb-color: #{$scrollbar-bg};
    --scrollbar-thumb-hover-color: #{$scrollbar-hover-bg};
}

.ng-scroll-content {
	width: 100%;
}

.ng-native-scrollbar-hider {
	scroll-padding: 4rem;
	scroll-behavior: smooth;
}