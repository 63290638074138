$ngx-dropzone-height: 150px;

[ngx-dropzone] {
	&.drag-upload {
		border: 1.5px dashed $input-border-color;
		height: $ngx-dropzone-height;
		transition: border-color 300ms;
		padding: 1rem 0.75rem;

		&.ngx-dz-hovered,
		&:hover {
			border-color: darken($input-border-color, 20%);
			border-style: dashed;

			& > ngx-dropzone-label {
				color: $body-color;
			}
		}

		& > ngx-dropzone-label {
			color: $body-color;
			font-size: $font-size-sm;
			transition: color 300ms;

			& > .zwicon {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto 0.5rem;
				border-radius: 50%;
				border: 1px solid $input-border-color;
				width: 40px;
				height: 40px;
				transition: border-color 300ms;
				font-size: 1.25rem;
			}
		}

		ngx-dropzone-image-preview {
			user-select: none;
			flex-shrink: 0;
			height: $ngx-dropzone-height - 30 !important;
			width: $ngx-dropzone-height - 30;
			min-height: $ngx-dropzone-height - 30 !important;
			margin: 0 0.25rem !important;

			& > img {
				height: 100%;
				min-height: $ngx-dropzone-height - 30 !important;
				width: 100%;
				object-fit: cover;
				border-radius: $border-radius;

				&[src=''] {
					display: none;
				}
			}

			& > ngx-dropzone-label {
				color: $headings-color;
				padding: 0 1rem;
				display: flex;
				align-items: center;
				font-size: $font-size-sm;
				background-color: $theme-50;
				border: 1px solid $theme-200;
				border-radius: $border-radius;
				height: 100%;
				margin: 0;
			}

			ngx-dropzone-remove-badge {
				z-index: 10;
				background-color: $white;

				& > svg {
					transform: scale(0.75);

					line {
						stroke: $theme-700 !important;
					}
				}

				&:hover {
					opacity: 0.85;
				}
			}
		}
	}
}
