// Functions, Mixins and Variables
@import './assets/scss/mixins';
@import './assets/scss/variables';

// Core
@import './assets/scss/fonts';
@import './assets/scss/base';
@import './assets/scss/print';
@import './assets/scss/layout';
@import './assets/scss/misc';
@import './assets/scss/toolbar';
@import './assets/scss/aside';
@import './assets/scss/login';
@import './assets/scss/charts';

// Bootstrap with updated utilities
@import "./assets/scss/bootstrap";

// Bootstrap Overrides
@import './assets/scss/bootstrap-overrides/dropdown';
@import './assets/scss/bootstrap-overrides/nav';
@import './assets/scss/bootstrap-overrides/breadcrumbs';
@import './assets/scss/bootstrap-overrides/card';
@import './assets/scss/bootstrap-overrides/table';
@import './assets/scss/bootstrap-overrides/carousel';
@import './assets/scss/bootstrap-overrides/button';
@import './assets/scss/bootstrap-overrides/date-picker';
@import './assets/scss/bootstrap-overrides/forms';
@import './assets/scss/bootstrap-overrides/close';
@import './assets/scss/bootstrap-overrides/time-picker';
@import './assets/scss/bootstrap-overrides/accordion';
@import './assets/scss/bootstrap-overrides/pagination';
@import './assets/scss/bootstrap-overrides/popover';
@import './assets/scss/bootstrap-overrides/toast';
@import './assets/scss/bootstrap-overrides/alert';

// Vendor Overrides
@import './assets/scss/vendor-overrides/ngx-scrollbars';
@import './assets/scss/vendor-overrides/quill';
@import './assets/scss/vendor-overrides/ng-select';
@import './assets/scss/vendor-overrides/ngx-dropzone';
@import './assets/scss/vendor-overrides/ngx-color-picker';
@import './assets/scss/vendor-overrides/ngx-slider';
@import './assets/scss/vendor-overrides/jsvectormap';
@import './assets/scss/vendor-overrides/ngx-drag-scroll';

.caps {
    text-transform: capitalize !important;
}


.lm-page-title {
    font-size: 2rem !important;
}

.no-margin {
    margin: 1px 0 !important;

}

.card {
 
    --bs-card-border-color: #266697  !important;
    background-color: #fff !important;
    border-radius: unset !important;
}

.content {
    padding: 10px !important;
}

.note-text-muted {

    color: #ae9a9a !important;
}

.dash-text-muted {

    color: #d8d8d8 !important;
}

.top-nav__item:hover {
    background-color: #f0f7fc;
    cursor: pointer;
}
.fs-9{
font-size: 7pt;
width: 100% !important;
    vertical-align: top;
    text-align: center;
}
.link {
    cursor: pointer !important;
}

.link-h:hover {

    background-color: #36688c !important;
}

.link-t:hover {

    color: rgb(250, 0, 71) !important;
}

.link-h:hover > .flex-grow-1 {


    color: #f0f7fc !important;
}

.link-t:hover>span>.text-headings {

    color: rgb(250, 0, 71) !important;
}


.link-t:hover>.text-headings {

    color: rgb(250, 0, 71) !important;
}

.link-t:hover>p {

    color: rgb(250, 0, 71) !important;
}


.alert-danger-form {
    border-color: #984444 !important;
    background-color: #ffe3e3 !important;
    color: #030000 !important;
}

.bg-grey {

    background-color: rgba(207, 207, 207, 0.19) !important;

}

.bg-grey-box {

    background-color: rgba(207, 207, 207, 0.50) !important;

}

.sml-text-left {
    display: flex;
    text-align: left;
    font-size: 0.6em;
    flex-wrap: nowrap;
    justify-content: left;
}


// .amber-link{



// }
.amber-link:hover {
    background-image: linear-gradient(45deg, #fbb034 0%, #ffdd00 100%);
    box-shadow: 0 3px 5px 0 rgba(193, 137, 36, 0.25);
    color: #205377 !important;
    cursor: pointer;
}


.red-link {
    color: #c90000 !important;
}

.red-link-hover:hover {
    color: #c90000 !important;
    cursor: pointer;
}

.red-link-hover:hover>i {
    color: #c90000 !important;
}

.red-link-hover:hover>div {
    color: #c90000 !important;
}

.required {
    color: #c9000090 !important;
}

.bg-alert>td {
    background-image: linear-gradient(45deg, #fd9d9d 0%, #fd9d9d 100%) !important;
}

.k-table-td {
    padding: 3px 3px !important;
    text-transform: capitalize;
}

.k-table {

    font-size: 12px !important;

}

.k-listview {
    font-size: 12px !important;
    line-height: 1.285714286 !important;
    cursor: pointer;
}

.dateListItem {

    height: 40px !important;
    padding: 5px 5px 5px 10px !important;
    cursor: pointer;

}

.w-25 {
    width: 24.8% !important;
    vertical-align: top;
    text-align: center;
}

.aside-link-p-1 {
    display: flex;
    align-items: center;
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    color: #205377;
}


.aside-link-p-1 :hover {
    color: #161c3b !important;
    background-color: lightcyan !important;
    cursor: pointer;
}

.p-x1 {
    padding: 0rem 0.2rem 0.2rem 0.2rem !important;
}

.m-x1 {
    margin: 0.2rem 0.2rem 0.2rem 0.2rem !important;
}


.k-grid th {
    padding: 8px 6px !important;
    text-transform: capitalize !important;
    white-space: nowrap;
}



.table6rem {
    font-size: 0.6.5rem !important;
}

.table7rem {
    font-size: 0.75rem !important;
}

.table7rem>td {
    white-space: nowrap !important;
    /* Prevents line breaks */
    overflow: hidden !important;
    /* Hides overflowing content */
    text-overflow: ellipsis !important;
    /* Truncates text with an ellipsis (...) */
}

.table8rem {
    font-size: 0.85rem !important;
}




.form-control {
    display: block !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    color: #000000 !important;
}

.form-label {
    margin-bottom: 0.1rem !important;
    font-size: 13 !important;
}

.dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
    min-width: 250px !important;
    background-attachment: local, local, scroll, scroll;
}

.dropdown-item {
    white-space: normal;
}


.flex-table {
    display: flex;
    flex-wrap: wrap;
    width: calc(100%);
}

.flex-table-header {
    font-weight: bold;
}

.flex-table-cell {
    cursor: pointer;
    flex: 1 1 auto;
    /* Allow columns to expand and shrink */
    overflow: hidden;
    /* Hide overflowing content */
    text-overflow: ellipsis;
    /* Truncate content with an ellipsis */
    white-space: nowrap;
    /* Prevent line breaks within cells */
    padding: 5px;
    /* Adjust padding as needed */
}


.btn-xt-sm {
    --bs-btn-padding-y: 0.175rem !important;
    --bs-btn-padding-x: 0.5rem !important;
    --bs-btn-font-size: 0.75rem !important;
    --bs-btn-border-radius: 0.2rem !important;
    color: #205377 !important;
    margin: 2px !important;
}


.svg-inline--fa {

    height: 1em !important;

}

.svg-inline--fa.fa-fw {
    width: 1em !important;
}























// OA IMPORT



.fc-daygrid-week-number {
    font-size: 1.2vh !important;
}

select.k-dropdown,
select.k-dropdowntree {

    padding: 0.5rem !important;

}





.hdrview .k-cell-inner {
    display: block !important;
    text-align: center !important;
}

.hdrview .k-cell-inner>.k-link {
    display: block !important;
}


.fc-event-time {
    display: none !important;
}


.fc-timegrid-slot {
    height: 4.0vh !important;
    border-bottom: 0;
}

.unavailable-active-cell {
    background-image: linear-gradient(150deg, #0A253F 0%, #0A253F 85%, #0A253F 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;


    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }
    }



}


.available-active-cell {
    background-image: linear-gradient(150deg, #00C406 0%, #00C406 85%, #00C406 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;


    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.55vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.80vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1vh + 1vw));
        }
    }


}

.appointment-active-cell {
    background-image: linear-gradient(45deg, #1492E6 0%, #1492E6 85%, #1492E6 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;

    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }
    }


}

.appointmentonly-active-cell {
    background-image: linear-gradient(45deg, #FFB71B 0%, #FFB71B 85%, #FFB71B 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;


    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }
    }

}

.appointmentvip-active-cell {
    background-image: linear-gradient(45deg, #8E1AE2 0%, #8E1AE2 85%, #8E1AE2 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;

    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }
    }


}

.special-book-active-cell {
    background-image: linear-gradient(45deg, #FF872F 0%, #FF872F 85%, #FF872F 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;

    color: #ffffff !important;

    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }
    }


}

.vip-booked-active-cell {
    background-image: linear-gradient(45deg, #c50ab9 0%, #c50ab9 85%, #E21AD3 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;

    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }

    }


}

.booked-active-cell {
    background-image: linear-gradient(45deg, #D33222 0%, #D33222 85%, #D33222 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;

    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }

    }


}



.reachable-active-cell {
    background-image: linear-gradient(45deg, #00B3C4 0%, #00B3C4 585%, #00B3C4 100%) !important;
    border-radius: 10vh;
    border: solid #ffffff 0.1px;
    color: #ffffff !important;

    .slot-type {
        font-size: calc(0.5 * (1.0vh + 0.70vw));

        @media (max-width:1480px) {
            font-size: calc(0.5 * (1.0vh + 0.65vw));
        }

        @media (max-width:1430px) {
            font-size: calc(0.5 * (1.0vh + 0.60vw));
        }


        @media (max-width:1380px) {
            font-size: calc(0.5 * (1.0vh + 0.90vw));
        }

        @media (max-width:1300px) {
            font-size: calc(0.5 * (1.0vh + 0.95vw));
        }

        @media (max-width:990px) {
            font-size: calc(0.5 * (1.1vh + 1.1vw));
        }

    }
}

.newevent-cell {
    background-image: linear-gradient(45deg, #e8f7ff 0%, #d9f1fc 100%) !important;
    color: #000000 !important;
}

.else-active-cell {
    background-color: #7fcbd7 !important;
}

.events-checkbox {
    border-color: #36688c;
    background-color: #fff;
}

.toast-title {
    font-weight: 400;
    text-shadow: none !important;
}

.calener_action_hover_class {

    display: block;

    .time-view {
        display: block;

        @media (max-width:1380px) {
            display: none;
        }
    }

    .time-view-end {

        @media (max-width:1720px) {
            display: none;
        }
    }


    a {
        visibility: hidden;
    }

}

.calener_action_hover_class:hover a {
    visibility: visible;
}





.pat-container {
    border-color: #77a8cb;
    position: relative;
    display: block;
    box-sizing: border-box;
}

.pat-container-box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.calendar-slot-time {
    .slot-hour {
        color: #0A253F;

        font-size: 1.85vh;
    }

    .slot-hour-post {
        color: #0A253F;
        font-size: 1.60vh;
    }

    .slot-day {
        color: #0A253F;
        font-size: 1.5vh;
    }
}

.calendar-slot-duration-row-odd {
    background-color: rgb(158, 185, 213, 0.10);
}

.calendar-slot-duration-row-even {
    background-color: rgb(255, 255, 255, 0.10);
}

.calendar .fc-theme-standard th {
    border-bottom: 0.1px solid #f5f8fb;
}


.fc-timeGridWeek-view .fc-daygrid-day-frame {
    height: 34px !important;
}

.fc-dayGridMonth-view .fc-daygrid-day-frame {
    min-height: 18vh;
}




.event-list-item-active {
    background-image: linear-gradient(45deg, #008996 0%, #006F79 50%, #00555C 100%) !important;
    border: solid 1px 0.1px;
    color: #fff !important;
    cursor: pointer;
}



.k-popup {
    padding: 0px !important;
}

.calendar {

    .fc .fc-timegrid-event {



        padding: 0px;
    }

    .fc .fc-daygrid-event {

        padding: 0px;

    }

    .fc .fc-event {
        border: #00000000 0px solid;
        padding: 0px;
    }

    .fc .fc-daygrid-day-top {
        justify-content: left;
        margin-left: 5px;
    }

    .fc .fc-daygrid-day-events {
        height: 100%;
    }

    .fc .fc-timegrid-axis-frame-liquid {
        height: 4vh;
    }

    .fc-daygrid-day-frame .fc-daygrid-day-frame {
        min-height: 18vh;
    }



    .fc .fc-timegrid-axis-cushion {
        font-size: 1.50vh
    }

    .fc-timegrid-event-harness-inset .fc-timegrid-event,
    .fc-timegrid-event.fc-event-mirror,
    .fc-timegrid-more-link {
        box-shadow: 0 0 0 0px #fff;
        box-shadow: 0 0 0 0px var(--fc-page-bg-color, #fff);
    }

}

.day-event {
    height: 3.5vh;
    position: relative;

    .day-event-list-item {
        border: #0A253F solid 0.3px;
        border-radius: 10vh;
        background-color: white;
        margin-top: 4px;
        margin-bottom: 4px;
        width: 100%;
        color: #0A253F;
        font-size: 1.25vh;


        .circle {
            height: 3.0vh;
            width: 3.0vh;
        }


    }

    .circle {
        height: 3.2vh;
        width: 3.2vh;
    }

    a {
        // <a> starts here
        visibility: hidden;
        background-color: #000a2575;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        color: #ffffff;
        position: absolute;
        margin-right: 19px;

        i {
            font-size: 1.25vh;

        }
    }

    &:hover {

        a {
            visibility: visible;
        }

    }

}


.not-event-type {




    .rectangle {
        background-image: linear-gradient(150deg, #7FC004 0%, #468E13 50%, #00555C 100%) !important;
        border-radius: 10vh;
        border: solid #ffffff 0.1px;
        color: #ffffff !important;
    }

    span {
        font-size: 1.25vh;
        text-align: center
    }

    .circle-no-event {
        height: 15vw;
        width: 15vw;
        background-image: linear-gradient(150deg, #7FC004 0%, #468E13 50%, #00555C 100%) !important;
        border-radius: 10vh;
        border: solid #ffffff 0.1px;
    }
}


.allday-btn-cell {
    background-image: linear-gradient(45deg, #bae9f0 0%, #c8ddf5 100%) !important;
    color: #284b6e !important;
    font-weight: 200 !important;
    border-radius: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    height: 100%;
    font-size: 1.60vh;



}

.all-day-event {

    height: 24px;
    position: relative;

    .all-day-event-list-item {
        border: #0A253F solid 0.3px;
        border-radius: 20px;
        background-color: white;
        width: 100%;
        color: #0A253F;
        font-size: 9.5px;


        .circle {
            height: 22px;
            width: 22px;
        }


    }





}

.card-hv-bg-light:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(224, 240, 251, var(--bs-bg-opacity));
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}


.bg-selected-row {
    --bs-table-striped-bg: #fbb034 !important;
    background-color: #fbb034 !important;
    --bs-table-accent-bg: #fbb034 !important;
    background-image: linear-gradient(45deg, #fbb034 0%, #ffdd00 100%) !important;

}

.bg-confirmation {
    background-color: #00c4072a !important;
    --bs-table-accent-bg: #00c4072a !important;
}

.bg-verification {
    background-color: #0055c42a !important;
    --bs-table-accent-bg: #0055c42a !important;
    --bs-table-bg-type:   #0055c42a !important;
}

.bg-appointment {
    background-color: #c400b42a !important;
    --bs-table-accent-bg: #c400b42a !important;
}




.bg-selected-tr>td {
    background-color: #ffdd0072 !important;
    --bs-table-accent-bg: #ffdd0072 !important;


}





.bg-kitchen {
    background-color: rgba(0, 133, 0, 0.22) !important;
    --bs-table-accent-bg: rgb(0, 133, 0, 0.22) !important;

}

.bg-bathroom {
    background-color: rgba(255, 0, 0, 0.22) !important;
    --bs-table-accent-bg: rgba(255, 0, 0, 0.22) !important;
}

.bg-paint {
    background-color: #ffc66a3c !important;
    --bs-table-accent-bg: #ffc66a3c !important;
}


.bg-aircondition {
    background-color: #2c3e5030 !important;
    --bs-table-accent-bg: #2c3e5030 !important;
}


.bg-porch {
    background-color: #80ff003f !important;
    --bs-table-accent-bg: #80ff003f !important;
}


.bg-addition {
    background-color: #ffa60046 !important;
    --bs-table-accent-bg: #ffa60046 !important;
}

.bg-landscape {
    background-color: #da627451 !important;
    --bs-table-accent-bg: #da627451 !important;
}

.bg-repair {
    background-color: #695acd3c !important;
    --bs-table-accent-bg: #695acd3c !important;
}

.bg-plumbing {
    background-color: #FFFFE0 !important;
    --bs-table-accent-bg: #FFFFE0 !important;
}

.bg-toxic {
    background-color: #FFDAB9 !important;
    --bs-table-accent-bg: #FFDAB9 !important;
}

.bg-aircondition4 {
    background-color: #00CED1 !important;
    --bs-table-accent-bg: #00CED1 !important;
}













// OA IMPORT END




///   map comp  ///
/// 
/// 



.google-map-calendar {
    width: calc(100%);
}

.information-cal-map {
    max-width: 720px;
    border-radius: 10px;
    background: #fff;
    position: absolute;
    left: 1vh;
    bottom: 1vh;
    z-index: 10;
}

.information-cal-map table tr td {
    border-bottom: 1px solid #9CAFC0;
    line-height: 1;
}

.information-cal-map table tr td img {
    width: 4vh;
    font-size: 1.6vh;
}

.information-cal-map table tr td span {
    background-color: transparent;
}

.information-cal-map .info-bottom ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
}

.information-cal-map .info-bottom li {
    text-align: center;
    display: inline-block;
    margin: 0.5vh 1vh;
}

.information-cal-map .info-bottom li .icn {
    width: 5vh;
    height: 5vh;
    border: 1px solid #707070;
    border-radius: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.information-cal-map .info-bottom li .icn:hover {
    color: #fff;
    background-color: #B8DB62;
    border-color: #B8DB62;
}

.information-cal-map .info-bottom li .icn img {
    width: 2.5vh;
}

.information-cal-map .info-bottom li span {
    color: #8798A6;
    text-transform: uppercase;
    display: block;
    font-size: 1.2vh;
}

.information-cal-map .info-bottom .fs-3 {
    font-size: 2.5vh !important;
}

.information-cal-map .confirmed {
    position: absolute;
    right: 10px;
    top: 10px;
}

.information-cal-map .confirmed img {
    width: 6vh;
}

.information-cal-map .confirmed span {
    display: block;
    font-size: 1.2vh;
}

//111111111
.assign-top {
    position: absolute;
    top: 6vh;
    right: 6vw;
    z-index: 10;
}

.assign-top .d-flex {
    justify-content: center;
}

.assign-top ul {
    list-style-type: none;
}

.assign-top ul li {
    text-align: center;
    display: inline-block;
    padding: 1vh;
    margin: 0 0.5vh 0.5vh;
}

.assign-top ul li p {
    font-size: 1.4vh;
    margin: 0 0 0.5vh;
}

.assign-top ul li .sing-user {
    margin: 0 1vh;
}

.assign-top ul li .img-icn {
    margin: 0 0 0.5vh;
}

.assign-top ul li .img-icn img {
    width: 4vh;
}

.assign-top ul li .info {
    min-height: 2.6vh;
}

.assign-top ul li span {
    display: block;
    line-height: 1;
    font-size: 1.3vh;
    color: #51C5EA;
}

.right-penel-map {
    position: fixed;
    z-index: 111;
    right: 0vw;
    top: 0;
    height: 100vh;
    width: 300px;
    background: #fff;
    border-left: 5px solid #d30f1c00;
    transform: translate(99%);
    transition: all .4s cubic-bezier(.46, .01, .32, 1);
    box-shadow: 0 3px 8px 8px rgba(215, 157, 48, 0.279);
}

.right-penel-map.active {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
}

.dial-pad {
    text-align: center;
    height: 100%;
    overflow: auto;
}

.dial-pad .dial-info {
    width: 17.8vh;
    height: 17.8vh;
    background: url(./assets/img/icons/dial-compo.png)no-repeat center center;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100%;
}

.dial-pad .dial-info span {
    display: block;
    color: #fff;
    position: relative;
    top: 1.5vh;
}

.dial-pad .dial-desc {
    text-align: center;
}

.dial-pad .key td {
    text-align: center;
    padding: 0.3vh 0;
}

.dial-pad .key td span {
    width: 6.5vh;
    height: 6.5vh;
    border-radius: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #687580;
    margin: 0 auto;
    color: #687580;
    font-size: 2vh;
}

.dial-pad .key td span.bg-red {
    border-color: rgb(253, 113, 113);
    color: #fff;
}

.dial-pad .key td span.bg-green {
    border-color: rgb(67, 210, 113);
    color: #fff;
}

.dial-pad .ph-roted {
    transform: rotate(135deg);
}

.dial-pad .sent-message .d-flex {
    justify-content: flex-end;
}

.dial-pad .sent-message .d-flex span {
    display: block;
}

.message-list .message-over {
    height: 85%;
    overflow: auto;
}

.message-list .aside-link {
    border-bottom: 1px solid #EDEDED;
}

.search_inp {
    position: relative;
}

.search_inp i {
    position: absolute;
    left: 10px;
    top: 7px;
}

.search_inp input {
    padding-left: 40px;
}

.message-sent {
    flex-direction: column;
    display: flex;
    height: 100vh;
}

.message-sent .message-data {
    flex-grow: 1;
}

.message-sent .d-flex {
    align-items: center;
    justify-content: space-between;
}

.message-sent .d-flex a {
    color: #AB47BC;
    font-size: 18px;
}

.message-sent .btn-block {
    display: block;
    width: 100%;
    color: #fff;
}

.message-sent .btn-block:hover {
    background: rgb(226, 108, 245);
}

.message-sent .sent-btn {
    position: relative;
}

.message-sent .sent-btn .btn {
    padding: 12px;
}

.message-sent .sent-btn img {
    position: absolute;
    left: 10px;
    top: 5px;
}


.personal-message {
    flex-direction: column;
    display: flex;
    height: 100vh;
}

.personal-message h4 {
    font-size: 2.2vh;
}

.personal-message h5 {
    font-size: 1.8vh;
}

.personal-message .text-center span {
    font-size: 1.2vh;
}

.personal-message .inner-data {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}

.personal-message .text-single {
    position: relative;
}

.personal-message .text-single input {
    height: 46px;
    border: 1px solid #707070;
    padding-right: 100px;
}

.personal-message .text-single .d-flex {
    align-items: center;
    position: absolute;
    right: 10px;
    top: 5px;
}

.personal-message .text-single .d-flex .plus {
    height: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.personal-message .text-single .d-flex .border-l {
    height: 21px;
    width: 1px;
    background-color: #707070;
    margin-right: 13px;
}

.personal-message .text-single .d-flex .micro {
    height: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #448AFF;
    color: #fff;
    border-radius: 180px;
}

.personal-message .sms {
    background-color: #EDF9FC;
    padding: 30px 20px 20px;
    border-radius: 10px;
    position: relative;
    max-width: 300px;
    margin-top: 30px;
}

.personal-message .sms img {
    position: absolute;
    top: -20px;
    left: 0;
}

.personal-message .sms p {
    min-height: 25px;
}

.personal-message .sms.bg_light {
    background-color: #FDE3F4;
}

.personal-message .sms.text-right {
    text-align: right;
    margin-left: auto;
}

.personal-message .sms.text-right img {
    left: auto;
    right: 0;
}

.toggle-panel {
    width: 52px;
    height: 129px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -55px;
    bottom: 55px;
    cursor: pointer;
}

.toggle-panel .close-icn {
    display: none;
}

.right-penel-map .toggle-panel {
    width: 52px;
    height: 129px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -55px;
    bottom: 55px;
    cursor: pointer;
}

.right-penel-map.active .toggle-panel {
    width: 52px;
    height: 129px;
    display: inline-block;
    background: url(./assets/img/icons/panel_path.png)no-repeat center center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -55px;
    bottom: 55px;
    cursor: pointer;
}

.toggle-panel .close-icn {
    display: none;
}

.right-penel-map.active .toggle-panel .close-icn {
    display: block;
}

.right-penel-map.active .toggle-panel .search-icn {
    display: none;
}

.demo-button {
    display: none;
}

.right-penel-map.active .demo-button {
    position: absolute;
    left: -300px;
    display: flex;
}


@media (min-width:1001px) {
    @media (max-height:767px) {

        .information-cal-map table tr td img {
            width: 40px;
            font-size: 16px;
        }

        .information-cal-map .info-bottom li .icn {
            width: 40px;
            height: 40px;
        }

        .information-cal-map .info-bottom li .icn img {
            width: 25px;
        }

        .information-cal-map .info-bottom li span {
            font-size: 12px;
        }

        .information-cal-map .info-bottom .fs-3 {
            font-size: 22px !important;
        }

        .information-cal-map .confirmed img {
            width: 50px;
        }

        .information-cal-map .confirmed span {
            display: block;
            font-size: 12px;
        }

        .assign-top ul li p {
            font-size: 13px;
        }

        .assign-top ul li .img-icn img {
            width: 40px;
        }

        .assign-top ul li span {
            font-size: 13px;
        }

        .right-penel-map {
            width: 350px;
        }

        .dial-pad .dial-info {
            width: 150px;
            height: 150px;
        }

        .dial-pad .dial-info span {
            top: 15px;
        }

        .dial-pad .key td span {
            width: 45px;
            height: 45px;
            font-size: 17px;
        }

        .personal-message h4 {
            font-size: 20px;
        }

        .personal-message h5 {
            font-size: 16px;
        }

        .personal-message .text-center span {
            font-size: 12px;
        }

    }
}






///  end map comp