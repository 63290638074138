.carousel-indicators {
	& > button {
		border-radius: 50%;
	}
}

.carousel-caption {
	text-shadow: 0 0 5px rgba($black, 0.25);

	h3 {
		color: $white;
	}
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
	background-size: auto;

	@include media-breakpoint-down(md) {
		transform: scale(0.65);
	}
}
